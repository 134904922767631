import './store.css';
import banner1 from '../../assets/img/jpg/tienda_banner1.jpg';
import banner2 from '../../assets/img/jpg/tienda_banner2.jpg';
import banner3 from '../../assets/img/jpg/tienda_banner3.jpg';
import { StoreHeader } from '../../components/StoreHeader/StoreHeader';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, EffectFade } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import { ProductCard } from '../../components/ProductCard/ProductCard';
import { Footer } from '../../components/Footer/Footer';
import { ProductsList } from '../../components/ProductsList/ProductsList';
import { CategoryFilter } from '../../components/CategoryFilter/CategoryFilter';
import { useEcommerce } from '../../context/EcommerceContext';
import { useState } from 'react';

export function Store() {
    const { 
        sortingMode, 
        setSortingMode,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
    } = useEcommerce();

    //FUNCTIONS
    const applyPriceFilters = () => {
        setMinPrice(localMinPrice);
        setMaxPrice(localMaxPrice);
    }

    //STATES
    const [localMinPrice, setLocalMinPrice] = useState(null);
    const [localMaxPrice, setLocalMaxPrice] = useState(null);

    return (
        <>
            <div className='store_body'>
                <StoreHeader />
                <section className='store_banner_section'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        loop={true}
                        speed={1000}
                        autoplay={{
                            delay: 3500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, EffectFade]}
                        className="store_main_slider"
                    >
                        <SwiperSlide className='store_main_slider_slide'>
                            <img src={banner3} alt='' />
                        </SwiperSlide>
                        <SwiperSlide className='store_main_slider_slide'>
                            <img src={banner1} alt='' />
                        </SwiperSlide>
                        <SwiperSlide className='store_main_slider_slide'>
                            <img src={banner2} alt='' />
                        </SwiperSlide>
                    </Swiper>
                </section>
                <div className='store_container'>
                    <div className='filters_container'>
                        <h2>
                            Filtros
                            <button className='primary_btn' onClick={(e) => e.target.parentNode.parentNode.classList.toggle('active')}>
                                Mostrar filtros
                            </button>
                        </h2>

                        {/* <div className='price_filter filter_box'>
                            <h2>
                                Ordenar por
                            </h2>
                            <div className='input'>
                                <select value={sortingMode} onChange={(e) => setSortingMode(e.target.value)}>
                                    <option value={3}>
                                        Relevancia
                                    </option>
                                    <option value={1}>
                                        Precio mayor a menor
                                    </option>
                                    <option value={0}>
                                        Precio menor a mayor
                                    </option>
                                </select>
                            </div>
                        </div> */}

                        <CategoryFilter />

                        {/* <div className='price_filter filter_box'>
                            <h2>
                                Precio
                            </h2>
                            <div className='input'>
                                <label>
                                    Mínimo
                                </label>
                                <input type='number' placeholder='$50.000' value={localMinPrice} onChange={(e) => setLocalMinPrice(e.target.value)} />
                            </div>
                            <div className='input'>
                                <label>
                                    Máximo
                                </label>
                                <input type='number' placeholder='$899.900' value={localMaxPrice} onChange={(e) => setLocalMaxPrice(e.target.value)} />
                            </div>
                            <button className='primary_btn' onClick={applyPriceFilters}>
                                Aplicar filtro
                            </button>
                        </div> */}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
                        <ProductsList />
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}
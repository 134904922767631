import { useEffect } from "react";
import { useServer } from "../../context/ServerContext";
import { ProductCard } from "../ProductCard/ProductCard";
import { backendEnv, endpoints } from "../../env";
import { useState } from "react";
import { useEcommerce } from "../../context/EcommerceContext";

export const ProductsList = () => {
    //HOOKS
    const { getRequest } = useServer();
    const { sortingMode, minPrice, maxPrice, selectedCategories } = useEcommerce();

    //STATES
    const [products, setProducts] = useState([]);

    //FUNCTIONS
    const loadProducts = () => {
        let data = {
            atze_website_id : backendEnv.websiteId, 
            sorting: sortingMode,
            min_price: parseInt(minPrice) * 100,
        };

        if (maxPrice !== null && maxPrice > 0) {
            data.max_price = parseInt(maxPrice) * 100;
        }

        if (selectedCategories.length > 0) {
            data.categories = selectedCategories;
        }

        getRequest(endpoints.ecommerce_product_list, data, {}, (res) => {
            if (res[0]) {
                res = res[1];
                setProducts(res.products);
            }
        });
    }

    //EFFECTS
    useEffect(() => {
        loadProducts();
    }, [sortingMode, minPrice, maxPrice, selectedCategories]);

    return (
        <>
            <div className='product_container'>
                {
                    products.map((product) => {
                        return <ProductCard product={product} key={'ecommerce_product_' + product.id} />
                    })
                }
            </div>
        </>
    );
}
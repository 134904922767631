import './product_card.css';
import imagePlaceholder from '../../assets/img/jpg/product_placeholder.jpg';
import { forwardRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import { useState } from 'react';
import { useEffect } from 'react';
import { backendEnv } from '../../env';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';

export const ProductCard = forwardRef(({product}, ref) => {
    //HOOKS
    const navigate = useNavigate();
    const { getLocalePrice } = useCart();

    //STATES
    const [image, setImage] = useState(imagePlaceholder);

    //FUNCTIONS
    const calculatePercentageDiscount = (regularPrice, salePrice) => {
        const discountAmount = regularPrice - salePrice;
        const percentageDiscount = (discountAmount / regularPrice) * 100;
        const roundedPercentageDiscount = Math.round(percentageDiscount);
      
        return roundedPercentageDiscount;
    }

    useEffect(() => {
        if (product.image) {
            setImage(backendEnv.url + product.image.path)
        }
    }, [product]);

    return (
        <div className="product_card" onClick={() => navigate('/tienda/product/' + product.id, {replace: true})}>
            <div className='media'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    speed={1000}
                    className="slider"
                >
                    <SwiperSlide className='slide'>
                        <img src={image} alt='' />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className='content'>
                <h2>
                    {product.name}
                </h2>
                {
                    product.saleActive === 1 && (
                        <span className='price_before'>
                            {getLocalePrice(product.regularPrice)}
                        </span>
                    )
                }
                {/* <div className='price'>
                    <span>
                        {
                            product.saleActive === 1 ? getLocalePrice(product.salePrice) : getLocalePrice(product.regularPrice)
                        }
                    </span>
                    {
                        product.saleActive === 1 && (
                            <p>
                                {calculatePercentageDiscount(product.regularPrice, product.salePrice)}% OFF
                            </p>
                        )
                    }
                </div> */}
            </div>
        </div>
    );
});
import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { useServer } from "./ServerContext";
import { backendEnv, endpoints } from "../env";

const EcommerceContext = createContext(undefined);

export const EcommerceProvider = ({children}) => {
    //HOOKS
    const { getRequest } = useServer();

    //STATES
    const [categories, setCategories] = useState([]);
    const [sortingMode, setSortingMode] = useState(3);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [selectedCategories, setSelectedCategories] = useState([]);
    
    //FUNCTIONS
    const loadCategories = () => {
        getRequest(endpoints.ecommerce_categories_list, { atze_website_id: backendEnv.websiteId }, {}, (res) => {
            if (res[0]) {
                res = res[1];
                setCategories(res.categories);
            }
        });
    }

    const addCategory = (id) => {
        let alreadyAdded = isCategoryAdded(id);
        
        if (!alreadyAdded) {
            setSelectedCategories(prev => [...prev, id]);
        }
    }

    const removeCategory = (id) => {
        if (isCategoryAdded(id)) {
            setSelectedCategories(prev => prev.filter(categoryId => categoryId !== id));
        }
    }

    const isCategoryAdded = (id) => {
        let alreadyAdded = false;

        for (let i = 0; i < selectedCategories.length; i++) {
            if (id === selectedCategories[i]) {
                alreadyAdded = true;
            }
        }

        return alreadyAdded;
    }

    //EFFECTS
    useEffect(() => {
        loadCategories();
    }, []);
    
    const value = {
        categories,
        setCategories,
        sortingMode,
        setSortingMode,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        addCategory,
        selectedCategories,
        setSelectedCategories,
        isCategoryAdded,
        removeCategory,
    }

    return (
        <EcommerceContext.Provider
            value={value}
        >
            {children}
        </EcommerceContext.Provider>
    )
}

export const useEcommerce = () => useContext(EcommerceContext);